module.exports={

//noton.cw-box.com
//	URL:'https://dev-services-noton.cw-box.com/graphql',
//       Auth:'https://dev-services.cw-box.com/identity/oauth/token',

	//noton.cwbos.com
	URL:'https://dev-services.cwcloud.in/cw-noton/graphql',
       Auth:'https://dev-services.cwcloud.in/cw-identity/oauth/token',
    
} 
